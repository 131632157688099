<template>
  <div class="content">
    <heads></heads>
    <div class="box">
      <div class="box-left">
        <trading-pair></trading-pair>
        <latest-transaction ref="latest"></latest-transaction>
      </div>
      <div class="box-main">
        <klines ref="klinesView" @changeEchartsType = 'changeEchartsType'></klines>

        <div class="quotation-box-head">
          <div style="display: flex;align-items: center;">
            <div class="quotation-box-nav" :class="[activeIndex == 1 ? 'quotation-active' : '']" @click="btn(1)">
              <div>{{ $t('transaction.xhjy') }}</div>
            </div>
            <div class="quotation-box-nav" :class="[activeIndex == 2 ? 'quotation-active' : '']" @click="btn(2)">
              <div>{{ $t('transaction.qhjy') }}</div>
            </div>
          </div>
        </div>

        <TradeSpot ref="tradeSpot" @isGetNewList='isGetNewList'></TradeSpot>
      </div>

      <div class="box-right">
        <TradeRight ref="tradeRight"></TradeRight>
      </div>
    </div>

    <TradeFooter @cancel='cancel' ref='tradeFooter'></TradeFooter>
    <!-- <footerb></footerb> -->
  </div>
</template>

<script>
import heads from '../../components/header.vue'
import footerb from '../../components/footer.vue'
import LatestTransaction from "@/components/order/latestTransaction";
import TradingPair from "@/components/order/tradingPair";
import Klines from "@/components/order/klines";
import TradeFooter from "./module/trade-footer.vue"
import TradeRight from "./module/trade-right.vue"
import TradeSpot from "./module/trade-spot.vue"

import {getDealIconList} from '@/api/deal.js'
import {sendWSPush} from "@/utils/socket";

export default {
  data() {
    return {
      activeIndex: 1,
      coin_name:'ETH',
      conin_id:3,
    }
  },
  components: {
    Klines,TradingPair,LatestTransaction,footerb,
    heads,TradeFooter,TradeRight,TradeSpot,
  },
  mounted() {
    if(this.$route.query.id){
      this.conin_id = this.$route.query.id
    }
    if(this.$route.query.coin_name){
      this.coin_name = this.$route.query.coin_name
    }
    this.$refs.latest.moneyType = this.coin_name
    this.$refs.klinesView.id =  this.conin_id * 1
    this.$refs.klinesView.name =  this.coin_name + '/USDT'
    this.$refs.klinesView.getCredInfo()

    this.getDealIconList(this.conin_id)
  },
  methods: {
    openSocket(id){
      sendWSPush({
      	'emit': 'setDealId',
      	'data': {
      		'dealId': id
      	}
      })
    },
    getDealIconList(dealId){
      getDealIconList({dealId:dealId}).then((res)=>{
        if(res.code === 1){
          this.openSocket(this.conin_id)
          this.$refs.tradeFooter.setNavOrUltitle('transaction',1,1);
          this.$refs.tradeSpot.getList(this.coin_name,this.conin_id);
          this.$refs.tradeRight.getList(res.data,this.coin_name);
          this.setKData(res.data)
        }
      })
    },
    setKData(data){
      if(this.$refs.klinesView){
        this.$refs.klinesView.coin_price = data.coinPrice
        this.$refs.klinesView.change_num = data.changeNum
        this.$refs.klinesView.txData.highPrice = data.highPrice
        this.$refs.klinesView.txData.lowPrice = data.lowPrice
        this.$refs.klinesView.txData.openPrice = data.openPrice
        this.$refs.klinesView.txData.volume = data.volume
      }
    },
    btn(id) {
      if (id === 2) {
        this.$router.push('./futures')
      }
    },
    changeEchartsType(e){
      this.coin_name = e.coin_name
      this.conin_id = e.id
      this.getDealIconList(this.conin_id)
      this.$refs.latest.moneyType = e.coin_name
    },
    isGetNewList(e){
      if(e){
        this.$refs.tradeFooter.setNavOrUltitle('transaction',1,1)
        this.$refs.tradeSpot.getList(this.coin_name,this.conin_id);
      }
    },
    cancel(e){
      if(e){
        this.$refs.tradeSpot.getList(this.coin_name,this.conin_id);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  background: #1f282f;
  .box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
    .box-left {
      width: 20%;
      height: 930px;
      background: #171E28;
      overflow-y: auto;
      overflow-x: hidden;
    }
    .box-left::-webkit-scrollbar { width: 0 !important }
    .box-main {
      width: 59%;
      height: 930px;
      background: #171E28;
      .quotation-box-head {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #1F282F;
        cursor: pointer;
        padding: 0 40px;
        box-sizing: border-box;
        .quotation-box-nav {
          display: flex;
          align-items: center;
          padding-bottom: 16px;
          color: rgba(255, 255, 255, 0.6);
          margin-right: 32px;
        }
        .quotation-active{
          border-bottom: 1px solid #FFC300;
          color: #FFC300 !important;
        }
      }
    }
    .box-right {
      width: 20%;
      height: 930px;
      background: #171E28;
    }
  }
}
</style>
