<template>
  <div class="box-main-tab">
    <div class="box-main-tab-left">
      <div class="box-main-tab-left-tit">
        <div>{{ $t('transaction.ky') }} {{USDT}} USTD</div>
        <div class="box-main-tab-left-img" @click="toRecharge">
          <div>{{ $t('transaction.cb') }}</div>
          <img src="@/assets/market/cb.png" alt="">
        </div>
      </div>
      <div class="box-main-kuang">
        <div class="display-flex align-items-center">
          <div class="margin10px">{{ $t('transaction.mrj') }}</div>
          <input class="c-f-f-14" type="number" v-model="buyData.money">
        </div>
        <div class="display-flex align-items-center">
          <div class="margin10px">USDT</div>
          <div class="box-main-kuang-border">
            <img class="up-img" @click="addOrReduce('add','buyMoney')" src="@/assets/market/up.png" alt="" style="margin-bottom: 4px;">
            <img class="up-img" @click="addOrReduce('reduce','buyMoney')" src="@/assets/market/down.png" alt="">
          </div>
        </div>
      </div>
      <div class="box-main-kuang">
        <div class="display-flex align-items-center">
          <div class="margin10px">{{ $t('transaction.mrl') }}</div>
          <input class="c-f-f-14" type="number" v-model="buyData.num">
        </div>
        <div class="display-flex align-items-center">
          <div class="margin10px">{{moneyType}}</div>
          <div class="box-main-kuang-border">
            <img class="up-img" @click="addOrReduce('add','buyNum')" src="@/assets/market/up.png" alt="" style="margin-bottom: 4px;">
            <img class="up-img" @click="addOrReduce('reduce','buyNum')" src="@/assets/market/down.png" alt="">
          </div>
        </div>
      </div>
      <div class="box-main-kuang">
        <div class="display-flex align-items-center">
          <div class="margin10px">{{ $t('transaction.cje') }}</div>
          <input class="c-f-f-14" type="number" disabled v-model="buyTotal">
        </div>
        <div class="display-flex align-items-center">
          <div class="margin10px">USDT</div>
          <div class="box-main-kuang-border">
            <i class="el-icon-coin"></i>
          </div>
        </div>
      </div>
      <div class="buy" @click="sumit(1)">{{ $t('transaction.mr') }} {{moneyType}}</div>
    </div>
    <!-- 1111 -->
    <div class="box-main-tab-left">
      <div class="box-main-tab-left-tit">
        <div>{{ $t('transaction.ky') }} {{moneyNum}} {{moneyType}}</div>
      </div>
      <div class="box-main-kuang">
        <div class="display-flex align-items-center">
          <div class="margin10px">{{ $t('transaction.mcj') }}</div>
          <input class="c-f-f-14" type="number" v-model="sellData.money">
        </div>
        <div class="display-flex align-items-center">
          <div class="margin10px">USDT</div>
          <div class="box-main-kuang-border">
            <img class="up-img" @click="addOrReduce('add','sellMoney')" src="@/assets/market/up.png" alt="" style="margin-bottom: 4px;">
            <img class="up-img" @click="addOrReduce('reduce','sellMoney')" src="@/assets/market/down.png" alt="">
          </div>
        </div>
      </div>
      <div class="box-main-kuang">
        <div class="display-flex align-items-center">
          <div class="margin10px">{{ $t('transaction.mcl') }}</div>
          <input class="c-f-f-14" type="number" v-model="sellData.num">
        </div>
        <div class="display-flex align-items-center">
          <div class="margin10px">{{moneyType}}</div>
          <div class="box-main-kuang-border">
            <img class="up-img" @click="addOrReduce('add','sellNum')" src="@/assets/market/up.png" alt="" style="margin-bottom: 4px;">
            <img class="up-img" @click="addOrReduce('reduce','sellNum')" src="@/assets/market/down.png" alt="">
          </div>
        </div>
      </div>

      <div class="box-main-kuang">
        <div class="display-flex align-items-center">
          <div class="margin10px">{{ $t('transaction.cje') }}</div>
          <input class="c-f-f-14" type="number" disabled v-model="sellTotal">
        </div>
        <div class="display-flex align-items-center">
          <div class="margin10px">USDT</div>
          <div class="box-main-kuang-border">
            <i class="el-icon-coin"></i>
          </div>
        </div>
      </div>

      <div class="buy" @click="sumit(2)" style="background:#FF6B65;">{{ $t('transaction.mc') }} {{moneyType}}</div>
    </div>
  </div>
</template>

<script>
  import {getCoinItem} from '@/api/money.js';
  import {addDeal} from '@/api/deal.js';
  export default {
    data(){
      return{
        isAJAX:false,
        USDT:0,
        moneyId:3,
        moneyType:'ETH',
        moneyNum:0,
        buyData:{
          money:0.000000,
          num:0.000000,
        },
        sellData:{
          money:0.000000,
          num:0.000000,
        },
      }
    },
    computed:{
      'buyTotal'(){
        return (this.buyData.money * this.buyData.num).toFixed(6)
      },
      'sellTotal'(){
        return (this.sellData.money * this.sellData.num).toFixed(6)
      },
    },
    methods:{
      getList(coin_name,id){
        this.moneyType = coin_name
        this.moneyId = id
        this.getMoney(2)
        this.getMoney(this.moneyId)
      },
      getMoney(id){
        getCoinItem({coinId:id}).then((res)=>{
          if(res.code === 1){
            if(id === 2){
              this.USDT = res.data.num * 1
            }else{
              this.moneyNum = res.data.num * 1
            }
          }
        })
      },
      toRecharge(){
        this.$router.push('./recharge')
      },
      addOrReduce(type,typeName){
        if(type === 'add'){
          if(typeName === 'buyMoney'){
            this.buyData.money = (this.buyData.money * 1 + 1).toFixed(6)
          }else if(typeName === 'buyNum'){
            this.buyData.num = (this.buyData.num * 1 + 1).toFixed(6)
          }else if(typeName === 'sellMoney'){
            this.sellData.money = (this.sellData.money * 1 + 1).toFixed(6)
          }else{
            this.sellData.num = (this.sellData.num * 1 + 1).toFixed(6)
          }
        }else{
          if(typeName === 'buyMoney'){
            if(this.buyData.money>0){
              this.buyData.money = (this.buyData.money * 1 - 1).toFixed(6)
            }
          }else if(typeName === 'buyNum'){
            if(this.buyData.num>0){
              this.buyData.num = (this.buyData.num * 1 - 1).toFixed(6)
            }
          }else if(typeName === 'sellMoney'){
            if(this.sellData.money>0){
              this.sellData.money = (this.sellData.money * 1 - 1).toFixed(6)
            }
          }else{
            if(this.sellData.num>0){
              this.sellData.num = (this.sellData.num * 1 - 1).toFixed(6)
            }
          }
        }
      },
      setNum(num){
        return  num
        // return num.replace(/\b(0+)/gi,'')
      },
      sumit(type){
        if(type === 1){
          // 买
          // if(this.buyData.num <= 0){
          //    return this.$message.error(this.$t('trade_spot.msgBuyNum'));
          // }
          // if(this.buyData.money <= 0){
          //    return this.$message.error(this.$t('trade_spot.msgBuyPrice'));
          // }
          // if(this.buyTotal>this.USDT){
          //    return this.$message.error(this.$t('trade_spot.msgUstdNotEnough'));
          // }

          this.buyData.money = this.setNum(this.buyData.money)
          this.buyData.num = this.setNum(this.buyData.num)
          if(this.buyData.money[0] === '.'){
            this.buyData.money = '0' + this.buyData.money
          }
          if(this.buyData.num[0] === '.'){
            this.buyData.num = '0' + this.buyData.num
          }
          if(this.isAJAX){
            return
          }
          this.isAJAX = true;
          this.latsFun(1,this.buyData.num,this.buyData.money);
        }else{
          // 卖
          // if(this.sellData.num <= 0){
          //    return this.$message.error(this.$t('trade_spot.msgSellNum'));
          // }
          // if(this.sellData.money <= 0){
          //    return this.$message.error(this.$t('trade_spot.msgSellPrice'));
          // }
          // if(this.sellTotal>this.moneyNum){
          //    return this.$message.error(this.$t('trade_spot.msgSellNumNotEnough'));
          // }
          this.sellData.money = this.setNum(this.sellData.money)
          this.sellData.num = this.setNum(this.sellData.num)
          if(this.sellData.money[0] === '.'){
            this.sellData.money = '0' + this.sellData.money
          }
          if(this.sellData.num[0] === '.'){
            this.sellData.num = '0' + this.sellData.num
          }
          if(this.isAJAX){
            return
          }
          this.isAJAX = true
          this.latsFun(2,this.sellData.num,this.sellData.money);
        }
      },
      latsFun(type,num,money){
        let fromData = {
          iconId:this.moneyId,
          type:type,
          coinNum:num,
          money:money,
        }
        addDeal(fromData).then((res)=>{
          if(res.code === 1){
            this.isAJAX = false;
            this.buyData = {
              money:0.000000,
              num:0.000000,
            },
            this.sellData = {
              money:0.000000,
              num:0.000000,
            },
            this.$message({
              message: this.$t('common_msg.success'),
              type: 'success'
            });
            this.$emit('isGetNewList',true);
          }else{
             this.isAJAX = false;
             // return this.$message.error(this.$t('common_msg.error'));
          }
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  .box-main-tab {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    padding: 16px 42px;
    justify-content: space-between;

    .display-flex{
      display: flex
    }
    .align-items-center{
      align-items: center;
    }
    .margin10px{
      margin: 0 10px;
    }
    .c-f-f-14{
      color: #FFF;font-size: 14px;
    }

    ::v-deep input::-webkit-outer-spin-button{
      -webkit-appearance: none !important;
    }
    ::v-deep input::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
    }
    ::v-deep input[type='number'] {
      -moz-appearance: textfield;
    }

    .buy {
      width: 100%;
      height: 36px;
      border-radius: 2px;
      background: #43CF7C;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: rgba(33, 33, 33, 1);
    }
    .box-main-tab-left {
      width: 48%;
      .box-main-tab-left-tit {
        display: flex;
        align-content: center;
        justify-content: space-between;
        margin-bottom: 10px;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.6);
      }

      .box-main-tab-left-img {
        color: #FFC300;
        display: flex;
        align-items: center;
        div {
          margin-right: 4px;
        }
        img {
          width: 16px;
          height: 16px;
        }
      }
      .box-main-kuang {
        width: 100%;
        height: 48px;
        border-radius: 2px;
        border: 1px solid #29343D;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: rgba(255, 255, 255, 0.6);
        font-size: 12px;
        margin-bottom: 26px;
        .box-main-kuang-border {
          width: 48px;
          height: 48px;
          border-radius: 2px;
          border: 1px solid #29343D;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          border-right: none;
          .up-img {
            width: 16px;
            height: 8px;
          }
        }
      }
    }
  }

</style>
