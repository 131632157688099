<template>
  <div>
    <div style="padding: 0 20px;">
      <div class="box-lef-kuang">
        <input style="color: #FFFFFF;" v-model="selectText" type="text" :placeholder="$t('transaction.ss')">
        <img class="search-img" src="@/assets/market/ss.png" alt="">
      </div>
      <div class="box-left-list" style="margin-bottom: 10px;">
        <div>{{ $t('transaction.bz') }}</div>
        <div>{{ $t('transaction.jg') }}</div>
        <div style="text-align: right;">{{ $t('transaction.zdf') }}</div>
      </div>
    </div>
    <div class="box-left-list ul-list" v-show="item.show" :class="[activeIndex == index ? 'active' : '']"
         v-for="(item, index) in getData" :key="index">
      <div><span style="color: #FFFFFF;">{{ item.coin_name }}</span>/USDT</div>
      <div style="color: rgba(255, 255, 255, 0.8);">{{ (item.coin_price * 1).toFixed(item.coin_price>1000?2:6) }}</div>
      <div style="text-align: right;">{{ item.change_num >= 0 ? '+' : '' }}{{ (item.change_num * 1).toFixed(2) }}%</div>
    </div>
  </div>
</template>

<script>
import {getIconPriceList} from "@/api/deal";

export default {
  name: "tradingPair",
  components: {},
  props: {},
  data() {
    return {
      selectText:'',
      activeIndex: 1,
      getData:[]
    }
  },
  watch: {
    'selectText':function (){
      this.getData.forEach((item)=>{
        if(this.selectText===''){
          item.show=true
        }else {
          item.show=item.coin_name.indexOf(this.selectText)>=0;
        }
      })
    }
  },
  mounted() {
    this.getIconPriceList()
    document.addEventListener("newCoinPrice",(e)=>{
      if(e.detail){
        this.setDate(e.detail)
      }
    })
  },
  methods: {
    getIconPriceList() {
      getIconPriceList().then(res => {
        if (res.code === 1) {
          this.setDate(res.data)
        }
      })
    },
    setDate(data){
      data.forEach((item)=>{
        if(this.selectText===''){
          item.show=true
        }else {
          item.show=item.coin_name.indexOf(this.selectText)>=0;
        }
      })
      this.getData = data
    }
  }
}
</script>

<style scoped lang="scss">

.search-img {
  width: 16px;
  height: 16px;
}

.box-lef-kuang {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 32px;
  border-radius: 2px;
  border: 1px solid #FFFFFF;
  padding: 8px;
  box-sizing: border-box;
  margin: 0 auto;
  margin-top: 20px;
  font-size: 12px;
  margin-bottom: 18px;
  color: rgba(255, 255, 255, 0.6);

}
.new {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  border-top: 1px solid #29343D;
  border-bottom: 1px solid #29343D;
  padding: 10px;
}

.box-left-list {
  display: flex;
  align-items: center;
}

.line {
  height: 1px;
  width: 100%;
  background: #29343D;
}

.ul-list {
  padding: 10px 20px;

}

.box-left-list div {
  color: rgba(255, 255, 255, 0.6);
  width: 33.33%;
  font-size: 10px;
  overflow: hidden;
}
</style>
